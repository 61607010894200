import env from '../../constants/env'
import { PublicUser } from '../users/types'

export default (user: PublicUser, userDeleted?: boolean) =>
  user
    ? [user.firstName, user.lastName].filter(Boolean).join(' ') || (
      env.isSixt ? user.clientName || "" : user.email
    )
    : userDeleted
    ? 'User deleted'
    : 'System'
